import React, { useEffect, useRef } from 'react'
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { animateScroll } from 'react-scroll';
import { motion, useAnimation, useInView } from "framer-motion";

import './index.css'
import { Link } from 'gatsby';

const scrollOptions = {duration: 600, delay: 0, smooth: 'easeInOutQuad'};

function getTop(ref: any): number {
  if (ref && ref.current) {
    return ref.current.offsetTop;
  }
  return -1;
}

export function Viewsection(props: any) {
  const { data, children } = props;
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);
  return (
    <section ref={data.ref} className={data.class}>
      <motion.div
        className='view-section-motion'
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={data.variants}
      >{children}</motion.div>
  </section>
  )
}

export default function index() {

  const sectionProps = [
    {
      contents: <div><div>one</div><div><Link to="/scroll">Scroll Page</Link></div></div>,
      class: "one",
      ref: useRef(null),
      variants: {
        visible: { opacity: 1, scale: 1, rotate: 720, transition: { delay: 0.1, duration: 0.5 } },
        hidden: { opacity: 0, scale: 0, rotate: 0 }
      }
    },
    {
      contents: <div>two</div>,
      class: "two",
      ref: useRef(null),
      variants: {
        visible: {  scale: [0, 2, 0, 2, 1], transition: { delay: 0.1, duration: 0.5 } },
        hidden: { scale: 0 }
      }
    },
    {
      contents: <div>three</div>,
      class: "three",
      ref: useRef(null),
      variants: {
        visible: {
          x: [0, -150, -300, -200, 0, 200, 300, 200, 0, -200, -300, -150, 0],
          y: [0, 0, 0, -200, -300, -200, 0, 200, 300, 200, 0, 0, 0],
          transition: { delay: 0.1, duration: 0.5 }
        },
        hidden: { x: 0, y: 0 }
      }
    },
    {
      contents: <div>four</div>,
      class: "four",
      ref: useRef(null),
      variants: {
        visible: { rotate: [0, 60, -60, 60, -60, 0], transition: { delay: 0.1, duration: 0.5 } },
        hidden: { rotate: 0 }
      }
    },
    {
      contents: <div>five</div>,
      class: "five",
      ref: useRef(null),
      variants: {
        visible: { scale: [0, 2, 1], rotate: 360, transition: { delay: 0.1, duration: 0.5 } },
        hidden: { scale: 0, rotate: 0 }
      }
    }
  ];
  const ref = sectionProps.map(p => p.ref);

  let toRef: NodeJS.Timeout;
  let intRef: NodeJS.Timeout;
  const handleScroll = () => {
    clearTimeout(toRef);
    toRef = setTimeout(() => {
      const position = window.pageYOffset;
      const topdiffs = ref.map((r) => Math.abs(getTop(r) - position));
      const min = Math.min(...topdiffs);
      const i = topdiffs.indexOf(min);
      animateScroll.scrollTo(getTop(ref[i]), scrollOptions);
    }, 400);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleScroll, { passive: true });
    intRef = setInterval(handleScroll, 700);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
      clearInterval(intRef);
      };
  }, []);

  function scrollUp() {
    let top = -1;
    for (let index = ref.length - 1; index >= 0; index--) {
      top = getTop(ref[index]);
      if (top < window.pageYOffset - 2) {
        break;
      }
    }
    if (top >= 0) {
      animateScroll.scrollTo(top, scrollOptions);
    }
  }

  function scrollDown() {
    let top = -1;
    for (let index = 0; index < ref.length; index++) {
      top = getTop(ref[index]);
      if (top > window.pageYOffset + 2) {
        break;
      }
    }
    if (top >= 0) {
      animateScroll.scrollTo(top, scrollOptions);
    }
  }

  return (
    <ReactScrollWheelHandler
      upHandler={scrollUp}
      downHandler={scrollDown}>
      <main className="index-page">
        {sectionProps.map((sp, i) =>
          <Viewsection key={i} data={sp}>{sp.contents}</Viewsection>
        )}
      </main>
    </ReactScrollWheelHandler>
  )
}
